import PropTypes from "prop-types";
import React from "react";
import { Font, FontSize } from "../../../config/theme";
import ParagraphStyled from "./styled";

function Paragraph({ children, font, fontSize, fontWeight, ...props }) {
  return (
    <ParagraphStyled
      $font={font}
      $fontSize={fontSize}
      $fontWeight={fontWeight}
      {...props}
    >
      {children}
    </ParagraphStyled>
  );
}

Paragraph.propTypes = {
  font: PropTypes.string,
  fontSize: PropTypes.oneOf(Object.values(FontSize)),
  fontWeight: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Paragraph.defaultProps = {
  font: Font.MUNKEN_SANS,
  fontSize: FontSize.BASE,
  fontWeight: "400",
  children: null,
};

export default Paragraph;
