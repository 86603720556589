import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Font, FontSize } from "../../../../config/theme";
import { SearchContext } from "../Context";
import {
  Container,
  Content,
  HeadingStyled,
  Image,
  ParagraphStyled,
} from "./styled";

export default function ResultPreview({ description, image, link, title }) {
  const context = useContext(SearchContext);

  return (
    <Container
      to={link}
      onClick={() => {
        context.setQuery("");
      }}
    >
      <Image>
        <GatsbyImage image={getImage(image)} alt={title} objectFit="cover" />
      </Image>
      <Content>
        <HeadingStyled level={4} font={Font.RECOLETA}>
          {title}
        </HeadingStyled>
        <ParagraphStyled fontSize={FontSize.SM}>
          {description.length >= 150
            ? `${description.slice(0, description.indexOf(" ", 140))} ...`
            : description}
        </ParagraphStyled>
      </Content>
    </Container>
  );
}

ResultPreview.propTypes = {
  description: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
