import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { slide as Menu } from "react-burger-menu";
import Cart from "../Cart";
import { SearchContext } from "../Search/Context";
import {
  CartButton,
  CartIcon,
  LogoStyled,
  MenuNav,
  MenuWrapper,
  NavContainer,
  NavItems,
  NavLink,
  NavLinkExternal,
  NavWrapper,
  SearchButton,
  SearchIcon,
  SideMenu,
  UserIcon,
} from "./styled";

// make a new context
const MenuContext = React.createContext();

// create the provider
function MenuProvider(props) {
  const [menuOpenState, setMenuOpenState] = useState(false);
  const { children } = props;
  const providerValue = useMemo(
    () => ({
      isMenuOpen: menuOpenState,
      closeMenu: () => setMenuOpenState(false),
      stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen),
    }),
    [menuOpenState, setMenuOpenState],
  );

  return (
    <MenuContext.Provider value={providerValue}>
      {children}
    </MenuContext.Provider>
  );
}

MenuProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function LogoLink({ scrolled }) {
  const ctx = useContext(MenuContext);
  return (
    <div>
      <Link to="/" aria-label="Zur Startseite" onClick={ctx.closeMenu}>
        <LogoStyled $scrolled={scrolled} />
      </Link>
    </div>
  );
}
LogoLink.propTypes = {
  scrolled: PropTypes.bool.isRequired,
};

const NavLinks = ({ links }) => {
  const ctx = useContext(MenuContext);
  return links.map((link) => (
    <NavLink key={link.name} to={link.target} onClick={ctx.closeMenu}>
      {link.name}
    </NavLink>
  ));
};

function MainMenu({ links }) {
  const searchCtx = useContext(SearchContext);
  return (
    <NavItems>
      <NavLinks links={links} />
      <SearchButton
        aria-label="Produktsuche öffnen"
        onClick={searchCtx.openSearch}
        $shortText="Produkte suchen ..."
        $text="Produkt oder Kategorie suchen ..."
      >
        <SearchIcon />
      </SearchButton>
      <NavLinkExternal
        href="https://portal.strollme.de"
        target="_blank"
        rel="noopener noreferrer"
      >
        <UserIcon />
      </NavLinkExternal>
      <Cart />
    </NavItems>
  );
}

MainMenu.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      target: PropTypes.string,
    }),
  ).isRequired,
};

function TopNavbar({ links }) {
  const ctx = useContext(MenuContext);
  const searchCtx = useContext(SearchContext);
  return (
    <MenuWrapper>
      <SearchButton
        $border
        aria-label="Produktsuche öffnen"
        onClick={() => {
          ctx.closeMenu();
          searchCtx.openSearch();
        }}
      >
        <SearchIcon />
      </SearchButton>
      <SideMenu>
        <Menu
          right
          noOverlay
          width="100%"
          isOpen={ctx.isMenuOpen}
          onStateChange={(state) => ctx.stateChangeHandler(state)}
        >
          <MenuNav>
            <NavLinks links={links} />
            <NavLinkExternal
              href="https://portal.strollme.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              <UserIcon />
            </NavLinkExternal>
            <CartButton
              data-embed="circuly-open-cart"
              aria-label="Warenkorb öffnen"
            >
              <CartIcon />
            </CartButton>
          </MenuNav>
        </Menu>
      </SideMenu>
    </MenuWrapper>
  );
}

TopNavbar.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      target: PropTypes.string,
    }),
  ).isRequired,
};

function Navbar({ links }) {
  const prevScrollY = useRef(0);
  const [scrolled, setScrolled] = useState(false);

  const changeColor = () => {
    const currentScrollY = document.documentElement.scrollTop;

    if (prevScrollY.current < currentScrollY || currentScrollY < 69) {
      setScrolled(false);
    }
    if (prevScrollY.current > currentScrollY && currentScrollY > 69) {
      setScrolled(true);
    }

    prevScrollY.current = currentScrollY;
  };
  useEffect(() => {
    window.addEventListener("scroll", changeColor);
  }, []);

  return (
    <MenuProvider>
      <NavContainer aria-label="Hauptnavigation" $scrolled={scrolled}>
        <NavWrapper>
          <LogoLink scrolled={scrolled} />
          <MainMenu links={links} />
          <TopNavbar links={links} />
        </NavWrapper>
      </NavContainer>
    </MenuProvider>
  );
}

Navbar.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      target: PropTypes.string,
    }),
  ).isRequired,
};

export default Navbar;
