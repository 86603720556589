import styled from "styled-components";
import { theme } from "styled-tools";
import LogoImage from "../../../static/images/logo.svg";

const mobileMedia = theme("MediaQuery.MD_DOWN");

export const Logo = styled(LogoImage)`
  width: 8rem;
  height: 3rem;
`;

export const VersionContainer = styled.small`
  display: block;
  margin-top: 1rem;
  font-size: 0.625rem;
  line-height: 1.5;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 2fr 2fr;
  padding: 2rem 0 4rem 0;

  ${mobileMedia} {
    grid-template-columns: 1fr;
  }
`;
