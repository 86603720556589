import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import { palette, theme } from "styled-tools";

const GlobalStyle = createGlobalStyle`
  ${reset}

  html, body {
    overflow-x: hidden;
    scroll-behavior: smooth;
    font-family: ${theme("Font.MUNKEN_SANS")};
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 1.25px;
    word-break: break-word;
  }

  .embla__container {
    display: flex;

    > .embla__slide {
      flex: 0 0 66.667%;
      max-width: 24rem;
      margin: 0 0.8rem;
    }
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  input[type=email],
  input[type=submit] {
    border-radius: 0;
    appearance: none;
  }

  strong {
    font-weight: bold;
  }

  .staticPages {
    font-family: ${theme("Font.MUNKEN_SANS")};
    white-space: pre-line;

    ul {
      list-style: unset;
      list-style-position: inside;
    }
    
    p, ul {
      font-weight: 500;
      font-size: ${theme("FontSize.BASE")};
      line-height: 1.5;
      letter-spacing: 0.4px;
      
    }

    h1 {
      margin-bottom: 5rem;
      font-size: 3rem;
    }

    h2 {
      font-size: 1.8rem;
      
      ${theme("MediaQuery.MD_UP")} {
        font-size: 2rem;
      }
    }

    h3 {
      font-size: ${theme("FontSize.LG")}; 
    }
  }

  .circuly-relative {
    z-index: 99999 !important;

    & .circuly-drawer {

      &--body {
        & .circuly-basket--main-container {
          padding: 0;
        }

        & .circuly-text-center {
          display: none;
        }

        & .circuly-product-card {
          border: none;
          box-shadow: none;

          &-body {
            padding: 0.25rem 1.25rem;
            border-bottom: 1px solid ${palette("gray", 3)};

            & .circuly-h6 {
              font-weight: 400;
              font-size: 1rem;
              letter-spacing: .0125em;
            }

            & .circuly-wrapper {
              margin-left: 0 !important;

              & img {
                width: 5rem;
              }
            }

            & button {
              color: ${palette("black", 0)};
            }
          }
          
          &-attributes { display: none; }
        }
      }
      
      &.circuly-outlined {
        padding: 0;
        background-color: ${palette("white", 0)};

        & .circly-drawer--header {
          display: grid;
          grid-template-columns: 1fr 3fr;
          padding: 3rem 1.25rem 2rem 1.25rem;
          border-bottom: 1px solid ${palette("gray", 3)};
        }

        & button {
          position: relative;
          padding: 0;
          border: none;
          box-shadow: none;
        }
      }

      & .circuly-header {
        &--toggle-holder {
          align-items: flex-start;
          justify-content: flex-start;
        }

        &--container {
          padding: 0;

          &::after {
            display: block;
            font-weight: 700;
            font-size: 1.5rem;
            font-family: ${theme("Font.RECOLETA")};
            letter-spacing: 0.6px;
            content: 'Dein Warenkorb';
          }

          & span {
            display: none;
          }
        }
      }

      & .circuly-basket--footer {
        padding: 0.5rem 1.25rem;
        background-color: ${palette("white", 0)};

        &__row {
          & p:first-child {
            font-weight: 400;
          }
        }

        & button {
          margin-top: 1.5rem;
          padding: 1.5rem 2rem;
          background-color: ${palette("gray", 0)};
          transition: ${theme("transition.base")};

          &:hover {
            background-color: ${palette("green", 1)};
          }

          &::after {
            display: block;
            font-weight: 500;
            font-size: 1rem;
            letter-spacing: 0.2px;
            cursor: pointer;
            content: 'Jetzt bestellen';
          }

          & span {
            display: none;
          }
        }
      }
    }
  }

  .productFocusImage {
    width: 100%;
    max-height: 25rem;
    border-radius: 0.75rem 0.75rem 0 0;
    
    ${theme("MediaQuery.SM_UP")} {
      position: absolute;
      height: 100%;
      max-height: 100%; 
      border-radius: 0 0.75rem 0.75rem 0;
    }
  }

  .heroImg {
    height: 25rem;
    overflow: hidden;
    
    ${theme("MediaQuery.SM_UP")} {
      border-radius: 0.75rem;
      position: absolute !important;
      padding-bottom: 3.5rem;
      height: 100%;
      box-sizing: content-box;
      overflow: visible;

      &:before {
        position: absolute;
        top: 0.625rem;
        left: 0.625rem;
        z-index: 1;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border: 2px solid ${palette("green", 1)};
        border-radius: 0.75rem;
        content: '';
      }
    }
  }
  
  .heroImgInner {
    width: 120% !important;

    ${theme("MediaQuery.SM_UP")} {
      width: 100% !important;
      border-radius: 0.75rem;
    }
  }

  body > #CybotCookiebotDialog {
    box-shadow: #12121226 2px 2px 30px 2px;

    #CybotCookiebotDialogBody {
      max-width: 100%;
      margin-right: auto;
      margin-left: auto;
      padding-top: 20px;
      padding-right: 10%;
      padding-bottom: 10px;
      padding-left: 10%;
      overflow: hidden;
      vertical-align: top;
    }

    .CybotCookiebotDialogBodyButton {
      z-index: 10;
      display: inline-block;
      min-width: 80px;
      margin-top: 8px;
      margin-right: 0;
      margin-left: 12px;
      padding: 10px 10px 10px;
      font-weight: 600;
      font-size: 9.5pt;
      white-space: nowrap;
      text-align: center;
    }
  }
`;

export default GlobalStyle;
