import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import CloseIcon from "../../../static/images/icons/close-cross.svg";
import {
  ButtonStyled,
  CloseText,
  FancyText,
  FormContent,
  FormContentContainer,
  FormDescription,
  FormHeading,
  FormModal,
  FormModalContent,
  ImageContainer,
  SubmitMessageContainer,
  SubmitMessageHeader,
  SubmitMessageText,
} from "./styled";

const formId = "81e6760e-9a97-4df0-a759-e04781dc0ccb";

function NewsletterOverlay() {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  let alreadyClosed = false;
  if (
    typeof window !== "undefined" &&
    localStorage.getItem("newsletterOverlayClosed")
  ) {
    const closedTime = parseInt(
      localStorage.getItem("newsletterOverlayClosed"),
      10,
    );
    if (closedTime) {
      const diff = (Date.now() - closedTime) / 60 / 60 / 24 / 7;
      if (diff <= 7) {
        alreadyClosed = true;
      }
    }
  }

  useEffect(() => {
    const createForm = () => {
      if (typeof window !== "undefined" && window.hbspt && !alreadyClosed) {
        window.hbspt.forms.create({
          portalId: "8995049",
          formId,
          target: "#hsOverlayForm",
        });
      }
    };

    const handleSubmit = (event) => {
      if (event.data.type === "hsFormCallback") {
        if (event.data.id !== formId) {
          return;
        }

        if (event.data.eventName === "onFormSubmitted") {
          setIsSubmitted(true);
        }
        if (event.data.eventName === "onFormReady" && !alreadyClosed) {
          setIsOpen(true);
        }
      }
    };

    window.addEventListener("message", handleSubmit);
    setTimeout(() => {
      createForm();
    }, 10000);
    return () => {
      window.removeEventListener("message", handleSubmit);
    };
  }, [alreadyClosed]);

  const closeForm = () => {
    setIsOpen(false);
    if (typeof window !== "undefined") {
      window.localStorage.setItem(
        "newsletterOverlayClosed",
        Date.now().toString(),
      );
    }
  };

  return (
    <FormModal open={isOpen}>
      <FormModalContent>
        <ButtonStyled onClick={closeForm}>
          <CloseIcon style={{ width: "1.375rem", height: "1.375rem" }} />
        </ButtonStyled>
        <ImageContainer>
          <StaticImage
            src="../../../static/images/newsletter.jpg"
            alt=""
            layout="constrained"
            objectFit="cover"
            objectPosition="center center"
            placeholder="blurred"
            style={{ height: "100%" }}
          />
        </ImageContainer>
        <FormContentContainer>
          {!isSubmitted ? (
            <>
              <FormHeading level={2}>
                Werde jetzt Teil der Bewegung!
              </FormHeading>
              <FormDescription>
                Registriere dich für unseren Newsletter und erfahre als Erster
                von neuen Produkten und exklusiven Angeboten.
              </FormDescription>
              <FormContent id="hsOverlayForm" />
              <CloseText onClick={closeForm}>Jetzt nicht</CloseText>
            </>
          ) : (
            <>
              <FancyText>Yeah!</FancyText>
              <SubmitMessageContainer>
                <SubmitMessageHeader>
                  Du bist Teil einer Bewegung!
                </SubmitMessageHeader>
                <SubmitMessageText>
                  Ab sofort erfährst du als Erster von neuen Produkten und
                  exklusiven Angeboten.
                </SubmitMessageText>
              </SubmitMessageContainer>
            </>
          )}
        </FormContentContainer>
      </FormModalContent>
    </FormModal>
  );
}

export default NewsletterOverlay;
