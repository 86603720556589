import styled from "styled-components";
import { ifProp, prop, theme } from "styled-tools";

const ColumnsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 2rem;
  align-self: flex-start;
  justify-content: ${prop("$justify")};

  ${theme("MediaQuery.SM_UP")} {
    flex-direction: ${ifProp("$flipDesktop", "row-reverse", "row")};
  }
`;

export default ColumnsWrapper;
