/* eslint-disable no-param-reassign */
import get from "lodash.get";
import set from "lodash.set";

export const ProductType = Object.freeze({
  MAIN_PRODUCT: "MainProduct",
  ACCESSORY: "Accessory",
  CONFIGURATOR: "Configurator",
});

const Accessories = Object.freeze({
  SUBSCRIPTION: "ACCESSORIES_SUBSCRIPTION",
  INSURANCE: "Diebstahlschutz",
});

export const OptionType = Object.freeze({
  SELECT: "select",
  SINGLE: "single",
});

function getOptionsList(product, options, label, forceLastTwo = false) {
  let checkTitle = product.title.toLowerCase();
  if (forceLastTwo) {
    const split = checkTitle.split(" ");
    checkTitle = split.slice(split.length - 2, split.length).join(" ");
  }

  return Object.values(options)
    .filter(({ name }) => checkTitle.includes(name.toLowerCase()))
    .map(({ name }) => ({ name: label, value: name }));
}

export const getNumericShopifyId = (shopifyId) =>
  shopifyId.replace(/^.*\/(\d+)$/, "$1");

export const mergeVariants = (products, config) => {
  const variants = [];

  products.forEach((product) => {
    let options = getOptionsList(
      product,
      config.conditions,
      config.options.condition.name,
    );

    const lowerCaseTitle = product.title.toLowerCase();

    if (
      !lowerCaseTitle.startsWith("tfk") &&
      !lowerCaseTitle.startsWith("mountain buggy nano duo") &&
      !lowerCaseTitle.startsWith("mountain buggy regencover") &&
      !lowerCaseTitle.startsWith("mountain buggy babytrage") &&
      !lowerCaseTitle.startsWith("nano duo regencover") &&
      !lowerCaseTitle.startsWith("minimeis")
    ) {
      options = options.concat(
        getOptionsList(product, config.types, config.options.types.name, true),
      );
    }

    if (
      product.title.startsWith("Woom-Bike") ||
      product.title.startsWith("Woom-OFF")
    ) {
      options = options.concat(
        getOptionsList(product, config.woomSize, config.options.woomSize.name),
      );
    }

    if (product.title.startsWith("Puky LS Pro")) {
      options = options.concat(
        getOptionsList(
          product,
          config.pukyLSProSize,
          config.options.pukyLSProSize.name,
        ),
      );
    }

    if (product.title.startsWith("Academy Bike")) {
      options = options.concat(
        getOptionsList(
          product,
          config.academyBikesSize,
          config.options.academyBikesSize.name,
        ),
      );
    }

    product.variants.forEach((variant) => {
      variants.push({
        ...variant,
        image: variant.image || product.featuredImage,
        selectedOptions: [...variant.selectedOptions, ...options],
        productId: getNumericShopifyId(product.shopifyId),
        productTitle: product.title,
      });
    });
  });

  return variants.map((variant) => ({
    ...variant,
    // strip gid://shopify/ProductVariant/ or gid://shopify/Product/
    variantId: getNumericShopifyId(variant.shopifyId),
  }));
};

const getOptionIndex = (options, title) => {
  let index = null;
  options.forEach((option, i) => {
    if (option.title === title) {
      index = i;
    }
  });
  return index;
};

const hasOptionValue = (optionValues, value) =>
  optionValues.map((item) => item.value).includes(value);

const getOrder = (options, key = false) => {
  const optionsOrder = {};
  Object.keys(options).forEach((item) => {
    const { name, order } = options[item];
    if (key) optionsOrder[item] = order;
    else optionsOrder[name] = order;
  });
  return optionsOrder;
};

const getOptionsOrder = (title, options) => getOrder(options)[title];

const getType = (options, name) => {
  const selectKeys = Object.keys(options)
    .filter((item) => options[item].isSelectOption)
    .map((key) => options[key].name);

  if (selectKeys.includes(name)) {
    return OptionType.SELECT;
  }

  return OptionType.SINGLE;
};

const getOptionField = (options, name, field, defaultValue) => {
  const option = Object.values(options).find(
    ({ name: optionName }) => optionName === name,
  );
  return get(option, field, defaultValue);
};

const hasCustomOption = (customOrders, slug, key) =>
  Object.keys(customOrders[slug]).includes(key);

const getValueMap = (option, config, slug) => {
  let valueMap;
  const hasCustomOrders =
    config.customOrders && Object.keys(config.customOrders).includes(slug);
  if (option.title === config.options.condition.name) {
    valueMap = getOrder(config.conditions);
  } else if (option.title === config.options.types.name) {
    if (
      hasCustomOrders &&
      hasCustomOption(config.customOrders, slug, "types")
    ) {
      valueMap = config.customOrders[slug].types;
    } else {
      valueMap = getOrder(config.types, true);
    }
  } else if (option.title === config.options.frameColor.name) {
    if (
      hasCustomOrders &&
      hasCustomOption(config.customOrders, slug, "frameColor")
    ) {
      valueMap = config.customOrders[slug].frameColor;
    } else {
      valueMap = getOrder(config.colors, true);
    }
  } else if (option.title === config.options.color.name) {
    if (
      hasCustomOrders &&
      hasCustomOption(config.customOrders, slug, "color")
    ) {
      valueMap = config.customOrders[slug].color;
    } else {
      valueMap = getOrder(config.colors, true);
    }
  } else if (option.title === config.options.fabricColor.name) {
    if (
      hasCustomOrders &&
      hasCustomOption(config.customOrders, slug, "fabricColor")
    ) {
      valueMap = config.customOrders[slug].fabricColor;
    } else {
      valueMap = getOrder(config.colors, true);
    }
  } else if (option.title === config.options.payment.name) {
    valueMap = getOrder(config.payments);
  }
  return (
    valueMap &&
    Object.keys(valueMap).reduce((acc, curr) => {
      acc[curr.toLowerCase()] = valueMap[curr];
      return acc;
    }, {})
  );
};

export const getAllOptions = (variants, config, slug) => {
  const options = [];
  variants.forEach((variant) => {
    variant.selectedOptions.forEach((option) => {
      if (option.name === "Title") {
        return;
      }
      // TODO: refactor to make universal
      if (option.name === "Zahlweise") {
        option.name = "Mindestmietdauer";
      }

      let index = getOptionIndex(options, option.name);
      if (index === null) {
        index = options.length;
        options.push({
          title: option.name,
          type: getType(config.options, option.name),
          popover: getOptionField(config.options, option.name, "popover", null),
          forceButtons: getOptionField(
            config.options,
            option.name,
            "forceButtons",
            false,
          ),
          chooseAnyVariant: getOptionField(
            config.options,
            option.name,
            "chooseAnyVariant",
            false,
          ),
          values: [],
        });
      }

      const { value } = option;
      const label = option.value;

      if (!hasOptionValue(options[index].values, value)) {
        options[index].values.push({
          value,
          label,
        });
      }
    });
  });
  options
    .sort(
      (a, b) =>
        getOptionsOrder(a.title, config.options) -
        getOptionsOrder(b.title, config.options),
    )
    .forEach((option) => {
      const valueMap = getValueMap(option, config, slug);
      if (valueMap) {
        option.values.sort(
          (a, b) =>
            valueMap[a.value.toLowerCase()] - valueMap[b.value.toLowerCase()],
        );
      } else {
        option.values.sort((a, b) => a.value - b.value);
      }
    });
  return options;
};

const trimProdPrefix = (title) => title.replace(/^(NEW)?PROD_/, ""); // strip "PROD_" or "NEWPROD_"

/**
 * PROD_GREENTOM => Greentom
 * PROD_MOUNTAIN_BUGGY => Mountain Buggy
 * PROD_WOOM_BIKE => Woom Bike
 */
export const getCleanTitleFromShopifyTitle = (title) =>
  trimProdPrefix(title).replaceAll(/(-|_)/g, " ").trim();

const getAccessorySlug = (title) =>
  title
    .toLowerCase()
    .replace(/ /g, "-") // replace All " " with "-"
    .replace(/[^\w-]+/g, ""); // trim spaces and special chars from beginning

export const getProductSlug = (title) =>
  getAccessorySlug(getCleanTitleFromShopifyTitle(title));
export const getFullProductPath = (title) =>
  `/produkt/${getProductSlug(title)}`;
export const getFullAccessoryPath = (title) =>
  `/zubehoer/${getAccessorySlug(title)}`;
export const getFullProductConfiguratorPath = (title) =>
  `${getFullProductPath(title)}/konfigurator`;

export const getMinVariantsPrice = (variants) =>
  Math.min(...variants.map((variant) => parseFloat(variant.price).toFixed(2)));

export const getMinPrice = (product) =>
  Math.min(
    ...product.products.map(({ variants }) => getMinVariantsPrice(variants)),
  );

const getVariantOptionMap = (options) => {
  if (!options || !options.length) {
    return {};
  }

  const { values } = options.shift();

  return Object.fromEntries(
    values.map(({ value }) => [value, getVariantOptionMap([...options])]),
  );
};

// creates a lookup map, where the final result is the index in the variants array
export const getVariantMapByOptionOrder = (
  { variants, variantOptions },
  config,
) => {
  const map = getVariantOptionMap([...variantOptions]);

  variants.forEach((variant, index) => {
    const selector = variant.selectedOptions
      .sort(
        (a, b) =>
          getOptionsOrder(a.name, config.options) -
          getOptionsOrder(b.name, config.options),
      )
      .reduce((acc, curr) => `${acc}.${curr.value}`, "")
      .substr(1);
    set(map, selector, index);
  });
  return map;
};

// is it a special accessory?
export const isSpecialAccessory = (product) =>
  product.title.includes(Accessories.INSURANCE) ||
  product.collections
    .map((item) => item.title)
    .includes(Accessories.SUBSCRIPTION);

export const getProductInfo = (metafields, key, namespace) => {
  const result =
    metafields[
      metafields.findIndex(
        (item) => item.key === key && item.namespace === namespace,
      )
    ];

  return result ? result.value : "";
};

export const getProductContent = (metafields) => {
  const filteredObj = metafields.filter((item) =>
    /^details_/.test(item.namespace),
  );

  const reduceObj = filteredObj.reduce((acc, item) => {
    const findItem = acc
      ? acc.findIndex((s) => s.namespace === item.namespace)
      : -1;
    if (findItem !== -1) {
      acc[findItem][item.key] = item.value;
    } else {
      const obj = {
        namespace: item.namespace,
        [item.key]: item.value,
      };
      acc.push(obj);
    }
    return acc;
  }, []);

  return reduceObj.sort((a, b) => a.namespace.localeCompare(b.namespace));
};

export const getSupportSectionContent = (metafields) => {
  const yellowTitles = metafields.find((m) => m.key === "yellow_title");
  const yellowText = metafields.find((m) => m.key === "yellow_text");

  const obj = {
    title: yellowTitles ? yellowTitles.value : "",
    text: yellowText ? yellowText.value : "",
  };

  return obj;
};

export const getCategoriesAndBrands = (items) => {
  const tmp = {
    brands: {},
    categories: {},
  };

  items.forEach((item) => {
    if (item.vendor.trim() !== "") {
      tmp.brands[item.vendor.toLowerCase()] = item.vendor;
    }

    item.tags
      .filter((tag) => tag.trim() !== "" && tag.toLowerCase() !== "setup_fee")
      .forEach((tag) => {
        tmp.categories[tag.toLowerCase()] = tag;
      });
  });

  return {
    brands: Object.values(tmp.brands).sort(),
    categories: Object.values(tmp.categories).sort(),
  };
};
