import { Link } from "gatsby";
import styled from "styled-components";
import { palette, theme } from "styled-tools";
import ArrowRightSvg from "../../../../static/images/icons/arrow-right.svg";
import Heading from "../../Heading";
import Paragraph from "../../Paragraph";

export const Container = styled.div`
  margin-top: 3rem;
  height: 100%;
  overflow-y: scroll;
`;

export const ResultsWrapper = styled.div`
  margin-bottom: 3rem;
`;

export const HeadingStyled = styled(Heading)`
  color: ${palette("black")};
  font-family: ${theme("Font.RECOLETA")};
  font-size: 1.375rem;
  font-weight: 500;
`;

export const LinkStyled = styled(Link)`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  text-decoration: none;
  cursor: pointer;

  &:hover ${HeadingStyled} {
    text-decoration: underline;
  }
`;

export const ArrowRightIcon = styled(ArrowRightSvg)`
  fill: ${palette("black")};
`;

export const SuggenstionsHeading = styled(Heading)`
  margin-top: 4rem;
  margin-bottom: 1rem;
  color: ${palette("gray", 2)};
  font-family: ${theme("Font.MUNKEN_SANS")};
  font-size: 1rem;
`;

export const SuggestionHeadingContainer = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const ParagraphStyled = styled(Paragraph)`
  line-height: 1.333;
`;
