import styled from "styled-components";
import { palette } from "styled-tools";
import BaseContainer from "../../Container";
import BaseWrapper from "../../Wrapper";

export const Overlay = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  overflow-y: scroll;
  overscroll-behaviour: contain;

  &.sc-enter {
    display: block;
    background-color: rgba(0, 0, 0, 0);

    & > div {
      transform: translateY(-100%);
    }
  }

  &.sc-enter-active,
  &.sc-enter-done,
  &.sc-exit {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
    transition: background-color 300ms ease-in-out;

    & > div {
      transform: translateY(0%);
      transition: transform 300ms ease-in-out 150ms;
    }
  }

  &.sc-exit-active,
  &.sc-exit-done {
    display: block;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 150ms ease-in-out 150ms;

    & > div {
      transform: translateY(-100%);
      transition: transform 200ms ease-in-out;
    }
  }

  &.sc-exit-done {
    display: none;
  }
`;

export const Container = styled(BaseContainer)`
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3rem;
  box-sizing: border-box;
  background-color: ${palette("white")};
`;

export const Wrapper = styled(BaseWrapper)`
  height: 100%;
`;
