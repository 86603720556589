import { createSlice } from "@reduxjs/toolkit";

const productDetailSlice = createSlice({
  name: "productDetail",
  initialState: {
    products: {},
  },
  reducers: {
    addProduct(
      state,
      {
        payload: {
          name,
          variants,
          variantOptions,
          variantMapByOption,
          options,
        },
      },
    ) {
      if (Object.prototype.hasOwnProperty.call(state.products, name)) {
        return;
      }

      state.products[name] = {
        activeVariantIndex: null,
        activeVariantSelector: null,
        updatedVariantStock: false,
        variants,
        variantOptions,
        variantMapByOption,
        options,
      };
    },
    setVariantAsActive(state, { payload: { name, index, selector } }) {
      if (!Object.prototype.hasOwnProperty.call(state.products, name)) {
        return;
      }

      state.products[name].activeVariantIndex = index;
      state.products[name].activeVariantSelector = selector;
    },
    updateVariantStock(state, { payload: { name, variants } }) {
      state.products[name].variants = state.products[name].variants.map(
        (variant) => ({
          ...variant,
          inventoryQuantity:
            variants[variant.variantId] || variant.inventoryQuantity,
        }),
      );
      state.products[name].updatedVariantStock = true;
    },
  },
});

export const { addProduct, setVariantAsActive, updateVariantStock } =
  productDetailSlice.actions;
export default productDetailSlice.reducer;
