import { graphql, useStaticQuery } from "gatsby";
import * as JsSearch from "js-search";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CSSTransition } from "react-transition-group";
import { deu, removeStopwords } from "stopword";
import Search from "..";
import {
  getCleanTitleFromShopifyTitle,
  getFullAccessoryPath,
  getFullProductPath,
  getMinPrice,
  getMinVariantsPrice,
} from "../../../../config/mapping/shopify";
import { Container, Overlay, Wrapper } from "./styled";

export const SearchContext = createContext([]);

export function SearchProvider({ children }) {
  const data = useStaticQuery(graphql`
    {
      accesoryCollections: allShopifyCollection(
        filter: { title: { in: ["ACCESSORIES"] } }
      ) {
        nodes {
          products {
            title
            description
            vendor
            tags
            metafield(namespace: "global", key: "title_tag") {
              value
            }
            variants {
              price
            }
            image: featuredImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1
                    height: 520
                    placeholder: BLURRED
                  )
                }
              }
            }
            thumbnail: featuredImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1
                    height: 180
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
      }

      productCollections: allShopifyCollection(
        filter: { title: { regex: "/^PROD_/" } }
      ) {
        nodes {
          title
          description
          metafield(namespace: "global", key: "title_tag") {
            value
          }
          products {
            title
            vendor
            tags
            metafield(namespace: "global", key: "title_tag") {
              value
            }
            variants {
              price
            }
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1.25
                  height: 520
                  placeholder: BLURRED
                )
              }
            }
          }
          thumbnail: image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1
                  height: 180
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }

      yaml: allDataYaml {
        nodes {
          search {
            suggestions {
              title
              description
              path
              term
              tag
            }
          }
        }
      }
    }
  `);

  const suggestions = useMemo(
    () => data.yaml.nodes[0].search.suggestions,
    [data],
  );

  const search = useMemo(() => {
    const stopwords = [...deu, "abo", "gebraucht", "neu", "&"];
    const splitPattern = /[\s-]+/;
    const documents = [
      ...data.productCollections.nodes.map(
        ({
          title,
          description,
          metafield,
          products: items,
          image,
          thumbnail,
        }) => ({
          keywords: removeStopwords(
            [
              ...new Set(
                [
                  ...(metafield && metafield.value
                    ? metafield.value.split(splitPattern)
                    : []),
                  ...(items || []).flatMap(
                    ({ title: itemTitle, metafield: itemMetafield, tags }) => [
                      ...itemTitle.split(splitPattern),
                      ...(itemMetafield && itemMetafield.value
                        ? itemMetafield.value.split(splitPattern)
                        : []),
                      ...tags,
                    ],
                  ),
                ].map((term) => term.toLowerCase()),
              ),
            ],
            stopwords,
          ),
          path: getFullProductPath(title),
          title: getCleanTitleFromShopifyTitle(title),
          description,
          vendors: items.map(({ vendor }) => vendor),
          tags: items.flatMap(({ tags }) => tags),
          image,
          thumbnail,
          price: getMinPrice({ products: items }),
          products: items,
          isAccessory: false,
          isPurchase: false,
        }),
      ),
      ...data.accesoryCollections.nodes.flatMap(({ products }) =>
        products.map(
          ({
            title,
            description,
            metafield,
            tags,
            variants,
            vendor,
            image,
            thumbnail,
          }) => ({
            keywords: removeStopwords(
              [
                ...new Set(
                  [
                    ...title.split(splitPattern),
                    ...(metafield && metafield.value
                      ? metafield.value.split(splitPattern)
                      : []),
                    ...tags,
                    "zubehör",
                  ].map((term) => term.toLowerCase()),
                ),
              ],
              stopwords,
            ),
            path: getFullAccessoryPath(title),
            title: getCleanTitleFromShopifyTitle(title),
            description,
            vendors: [vendor],
            tags,
            image,
            thumbnail,
            price: getMinVariantsPrice(variants),
            isAccessory: true,
            isPurchase: tags.includes("Kaufprodukt"),
          }),
        ),
      ),
    ];

    const jsSearch = new JsSearch.Search("path");
    jsSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
    jsSearch.searchIndex = new JsSearch.UnorderedSearchIndex();
    jsSearch.addIndex("keywords");
    jsSearch.addDocuments(documents);

    return jsSearch.search.bind(jsSearch);
  }, [data]);

  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);

  const debouncedSearch = debounce((q) => {
    setResults(q.length > 0 ? search(q) : []);
  }, 300);

  useEffect(() => debouncedSearch(query), [debouncedSearch, query]);

  const value = useMemo(
    () => ({
      closeSearch: () => setIsOpen(false),
      openSearch: () => setIsOpen(true),
      query,
      results,
      resultsPagePath: "/suche",
      search: (q) => search(q), // Intended for static use only, i.e. no user input
      setQuery,
      suggestions,
    }),
    [query, results, setQuery, search, suggestions],
  );

  const escFunction = useCallback(
    (event) => {
      if (event.key === "Escape") {
        value.closeSearch();
        value.setQuery("");
      }
    },
    [value],
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const ref = useRef(null);

  return (
    <SearchContext.Provider value={value}>
      <CSSTransition
        classNames="sc"
        in={isOpen}
        timeout={300}
        onEnter={() => ref.current.focus()}
      >
        <Overlay onClick={value.closeSearch}>
          <Container>
            <Wrapper>
              <Search ref={ref} />
            </Wrapper>
          </Container>
        </Overlay>
      </CSSTransition>
      {children}
    </SearchContext.Provider>
  );
}

SearchProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
