import PropTypes from "prop-types";
import React from "react";
import HeadingStyled from "./styled";

function Heading({ level, font, children, ...props }) {
  return (
    <HeadingStyled {...{ level, font, ...props }}>{children}</HeadingStyled>
  );
}

Heading.displayName = "Heading";

Heading.propTypes = {
  ...HeadingStyled.propTypes,
  children: PropTypes.node.isRequired,
};

export default Heading;
