import styled from "styled-components";
import { prop, withProp } from "styled-tools";

export const ColumnViewMediaThreshold = "MediaQuery.SM_DOWN";

const Col = styled.div`
  position: relative;
  align-self: ${prop("$align")};
  width: 100%;

  ${prop("$breakpoint")} {
    width: ${withProp(
      "$size",
      (size) => `calc(${(size / 12) * 100}% - ${2 - 2 / size}rem)`,
    )};
  }
`;

export default Col;
