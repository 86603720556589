import { Link } from "gatsby";
import React from "react";
import styled, { css } from "styled-components";
import { ifProp, palette, theme } from "styled-tools";
import Cart from "../../../static/images/icons/cart.svg";
import SearchIconSvg from "../../../static/images/icons/search.svg";
import User from "../../../static/images/icons/user.svg";
import Logo from "../../../static/images/logo.svg";
import Container from "../Container";
import Wrapper from "../Wrapper";

export const LogoStyled = styled(Logo)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  display: block;
  width: ${ifProp("$scrolled", "6rem", "7.75rem")};
`;

export const NavContainer = styled((props) => (
  <Container as="nav" {...props} />
))`
  position: ${ifProp("$scrolled", "fixed", "absolute")};
  top: 0;
  z-index: 1030;
  padding-top: ${ifProp("$scrolled", "0.5rem", "2rem")};
  padding-bottom: ${ifProp("$scrolled", "0.5rem", "2rem")};
  margin-top: ${ifProp("$scrolled", "0", "2rem")};
  background-color: ${ifProp("$scrolled", palette("green", 3), "transparent")};
  box-shadow: ${ifProp("$scrolled", "0 2px 4px 0 rgba(0, 0, 0, 0.25)", "none")};

  & #cartIcon {
    margin-bottom: 1.025rem;

    ${theme("MediaQuery.SM_UP")} {
      margin-bottom: 0;
    }

    ${theme("MediaQuery.MD_UP")} {
      margin-left: 1.1rem;
    }

    & button {
      box-shadow: none;
    }
  }
`;
export const NavWrapper = styled(Wrapper)`
  position: relative;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CartButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: 0;
  outline: 0;
  box-shadow: "unset";

  &:hover,
  &:focus {
    cursor: pointer;
  }
`;
export const CartIcon = styled(Cart)`
  position: relative;
  width: 1.25rem;
  height: 1.25rem;
`;

export const SearchButton = styled.button`
  appearance: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0.75rem;
  outline: 0;
  background: none;
  box-shadow: none;
  font-family: ${theme("Font.MUNKEN_SANS")};
  font-size: 0.75rem;
  color: ${palette("green", 0)};

  &:hover,
  &:focus {
    cursor: pointer;
  }

  ${theme("MediaQuery.SM_UP")} {
    width: 12rem;
    border: 0;
    padding: 0.75rem 0.75rem 0.625rem 1rem;
    margin-bottom: 0.1875rem;
    margin-left: 0.9rem;
    background-color: ${palette("white")};
    color: ${palette("green", 1)};

    &:before {
      display: block;
      content: ${({ $shortText }) => `"${$shortText}"`};
    }
  }

  ${theme("MediaQuery.MD_UP")} {
    width: 18rem;
    margin-left: 2rem;

    &:before {
      display: block;
      content: ${({ $text }) => `"${$text}"`};
    }
  }
`;

export const SearchIcon = styled(SearchIconSvg)`
  position: relative;
  width: 2.25rem;
  height: 2.25rem;
  fill: currentColor;
  stroke: currentColor;
  stroke-width: 0.5px;

  ${theme("MediaQuery.SM_UP")} {
    width: 1.25rem;
    height: 1.25rem;
    stroke-width: 1px;
  }
`;

export const UserIcon = styled(User)`
  position: relative;
  width: 1.25rem;
  height: 1.25rem;
`;

const navLinkStyle = css`
  margin-bottom: 1.025rem;
  padding: 0 1rem;
  color: ${palette("black")};
  font-weight: bold;
  font-size: 1rem;
  font-family: ${theme("Font.MUNKEN_SANS")};
  line-height: 1.4;
  letter-spacing: 0.35px;
  text-align: center;
  text-decoration: none;
  border-bottom: 1px solid transparent;

  &[aria-current="page"],
  &:hover {
    border-bottom: 2px solid currentColor;
  }

  ${theme("MediaQuery.SM_UP")} {
    margin-bottom: 0;
    margin-left: 1.1rem;
    padding: 0 0.45rem;
  }

  ${theme("MediaQuery.MD_UP")} {
    margin-left: 2rem;
    padding: 0 1rem;
  }
`;

export const NavLinkExternal = styled.a`
  ${navLinkStyle}
`;

export const NavLink = styled((props) => <Link {...props} />)`
  ${navLinkStyle}
`;

export const NavItems = styled.div`
  display: none;

  ${theme("MediaQuery.SM_UP")} {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    margin-left: 7.5rem;
    text-align: center;
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;

  ${theme("MediaQuery.SM_UP")} {
    display: none;
  }
`;

export const SideMenu = styled.div`
  /* Copied directly from react-burger-menu docs */
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: relative;
    width: 36px;
    height: 30px;
  }
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    height: 3px !important;
    border-radius: 2px;
    background: ${palette("black")};
  }
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    top: 3rem !important;
    right: 1rem !important;
    padding: 0.5rem !important;
  }
  /* Color/shape of close button cross */
  .bm-cross {
    height: 32px !important;
    background: ${palette("black")};
  }
  /* General sidebar styles */
  .bm-menu {
    padding: 3rem 1.5rem 1.5rem;
    font-size: 1.15em;
    background: ${palette("white")};
  }
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
  }
  .bm-menu-wrap {
    top: 0;
    transform: translate3d(0, 0, 0);
  }
`;

export const MenuNav = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  ${theme("MediaQuery.SM_UP")} {
    display: none;
  }

  &:focus {
    outline: none;
  }
`;
