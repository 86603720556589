import React, { useContext } from "react";
import { FontSize } from "../../../../config/theme";
import Column from "../../Column";
import Columns from "../../Columns";
import Tag from "../../Tag";
import { SearchContext } from "../Context";
import ResultPreview from "../ResultPreview";
import {
  ArrowRightIcon,
  Container,
  HeadingStyled,
  LinkStyled,
  ParagraphStyled,
  ResultsWrapper,
  SuggenstionsHeading,
  SuggestionHeadingContainer,
} from "./styled";

export default function ResultList() {
  const context = useContext(SearchContext);

  return (
    <Container>
      <ResultsWrapper>
        <Columns>
          {context.results
            .slice(0, 4)
            .map(({ path, title, description, thumbnail }) => (
              <Column key={path} size={6}>
                <ResultPreview
                  image={thumbnail.localFile}
                  title={title}
                  description={description}
                  link={path}
                />
              </Column>
            ))}
        </Columns>
      </ResultsWrapper>
      {context.results.length > 4 && (
        <LinkStyled to={context.resultsPagePath}>
          <ArrowRightIcon />
          <HeadingStyled level={3}>Alle Ergebnisse zeigen</HeadingStyled>
        </LinkStyled>
      )}
      <SuggenstionsHeading level={4}>Vorschläge</SuggenstionsHeading>
      <Columns>
        {context.suggestions.map(({ title, description, path, tag }) => (
          <Column key={title} size={4}>
            <LinkStyled to={path}>
              <ArrowRightIcon />
              <div>
                <SuggestionHeadingContainer>
                  <HeadingStyled level={3}>{title}</HeadingStyled>
                  {!!tag && <Tag>{tag}</Tag>}
                </SuggestionHeadingContainer>
                <ParagraphStyled fontSize={FontSize.SM}>
                  {description}
                </ParagraphStyled>
              </div>
            </LinkStyled>
          </Column>
        ))}
      </Columns>
    </Container>
  );
}
