import React from "react";
import styled from "styled-components";
import { ifProp, palette, theme } from "styled-tools";
import { Breakpoint } from "../../../config/theme";
import Container from "../Container";
import Heading from "../Heading";
import Paragraph from "../Paragraph";
import Wrapper from "../Wrapper";

export const FormModal = styled((props) => (
  <Container {...props} as="section" />
))`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1700;
  display: ${ifProp("open", "block", "none")};
  transform: translate(-50%, -50%);
`;

export const FormModalContent = styled((props) => (
  <Wrapper maxWidth={Breakpoint.MD} {...props} />
))`
  position: relative;
  padding: 4.75rem 1.25rem 2rem;
  background-color: ${palette("white")};
  border-radius: 0.75rem;
  box-shadow: 30px 30px 50px 0 rgba(0, 0, 0, 0.25);
  overflow: hidden;

  ${theme("MediaQuery.SM_UP")} {
    display: grid;
    grid-template-columns: 40% 60%;
    padding: 0;
  }
`;

export const ButtonStyled = styled.button`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  padding: 0.875rem;
  background-color: transparent;
  border: 0;
  outline: 0;
  box-shadow: unset;

  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

export const ImageContainer = styled.aside`
  display: none;

  ${theme("MediaQuery.SM_UP")} {
    display: block;
  }
`;

export const FormContentContainer = styled.div`
  ${theme("MediaQuery.SM_UP")} {
    padding: 7.5rem 4.5rem 2rem;
  }
`;

export const FormHeading = styled(Heading)`
  margin-bottom: 1.25rem;
  font-weight: 700;
  font-size: 2rem;
  font-family: ${theme("Font.RECOLETA")};
  letter-spacing: 0.8px;

  ${theme("MediaQuery.SM_UP")} {
    margin-bottom: 2rem;
  }
`;

export const FormDescription = styled(Paragraph)`
  margin-bottom: 1.25rem;
  font-weight: bold;
  line-height: 1.25;

  ${theme("MediaQuery.SM_UP")} {
    margin-bottom: 2rem;
  }
`;

export const FormContent = styled.div`
  label,
  input[type="email"],
  .hs-error-msgs {
    margin-bottom: 0.5rem;
    font-weight: 500;
    font-size: 0.875rem;
    font-family: ${theme("Font.MUNKEN_SANS")};
    letter-spacing: 0.35px;
  }

  label {
    display: block;
  }

  input[type="email"] {
    width: 100%;
    padding: 0.625rem;
    border: 1px solid ${palette("black")};

    &:focus {
      color: ${palette("red", 1)};
      border-color: ${palette("red", 1)};
      outline: 0;
    }
  }

  .hs-error-msg {
    color: ${palette("red", 1)};
    font-weight: 400;
  }

  input[type="submit"] {
    width: 100%;
    margin-top: 0.875rem;
    padding: 0.5rem;
    color: ${palette("white")};
    font-weight: 500;
    font-size: 1rem;
    font-family: ${theme("Font.MUNKEN_SANS")};
    letter-spacing: 0.2;
    background-color: ${palette("black")};
    border: 0;

    &:hover,
    &:focus {
      cursor: pointer;
    }

    ${theme("MediaQuery.SM_UP")} {
      margin-top: 2rem;
    }
  }
`;

export const CloseText = styled.button`
  display: block;
  margin: 2rem auto 0;
  font-family: ${theme("Font.MUNKEN_SANS")};
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  outline: 0;
  box-shadow: unset;

  &:hover,
  &:focus {
    text-decoration: none;
    cursor: pointer;
  }
`;

export const FancyText = styled.p`
  position: absolute;
  top: 3rem;
  left: 50%;
  z-index: 1710;
  color: ${palette("yellow", 2)};
  font-weight: 700;
  font-size: 10rem;
  font-family: ${theme("Font.RECOLETA")};
  transform: rotate(-6deg) translateX(-50%);
`;

export const SubmitMessageContainer = styled.div`
  position: relative;
  z-index: 1720;
  padding-top: 10rem;
`;

export const SubmitMessageHeader = styled(Paragraph)`
  margin-bottom: 1.5rem;
  font-weight: bold;
  font-size: 2rem;
  line-height: 1.19;
  letter-spacing: 0.8px;

  ${theme("MediaQuery.SM_UP")} {
    margin-left: -3rem;
  }
`;

export const SubmitMessageText = styled(Paragraph)`
  font-weight: bold;
  line-height: 1.25;

  ${theme("MediaQuery.SM_UP")} {
    padding: 2rem 0 6rem;
  }
`;
