import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { prop } from "styled-tools";
import { Font } from "../../../config/theme";

function Headings({ level, font, ...rest }) {
  const hLevel = Math.max(1, Math.min(parseInt(level, 10), 6));
  const Htag = `h${hLevel}`;

  return <Htag {...rest} />;
}

Headings.propTypes = {
  level: PropTypes.number.isRequired,
  font: PropTypes.oneOf(Object.values(Font)).isRequired,
};

const HeadingStyled = styled(Headings)`
  font-family: ${prop("font")};
`;

HeadingStyled.defaultProps = {
  font: Font.MUNKEN_SANS,
};

export default HeadingStyled;
