import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import FacebookImage from "../../../../static/images/icons/facebook.svg";
import InstagramImage from "../../../../static/images/icons/instagram.svg";
import SocialLink from "./styled";

function Footer() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          socialLinks {
            facebook
            instagram
          }
        }
      }
    }
  `);

  return (
    <div>
      <SocialLink
        href={data.site.siteMetadata.socialLinks.facebook}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Zu Facebook"
      >
        <FacebookImage />
      </SocialLink>
      <SocialLink
        href={data.site.siteMetadata.socialLinks.instagram}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Zu Instagram"
      >
        <InstagramImage />
      </SocialLink>
    </div>
  );
}

export default Footer;
