import styled from "styled-components";
import { palette, theme } from "styled-tools";
import CloseIconSvg from "../../../static/images/icons/close-cross.svg";
import SearchIconSvg from "../../../static/images/icons/search.svg";

export const SearchContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;

  ${theme("MediaQuery.MD_UP")} {
    gap: 1rem;
  }
`;

export const SearchIcon = styled(SearchIconSvg)`
  flex: 0 0 auto;
  display: block;
  width: 2rem;
  height: 2rem;
  color: ${palette("black")};
  fill: currentColor;
  stroke: currentColor;
  stroke-width: 1;
`;

export const InputStyled = styled.input`
  appearance: none;
  width: 100%;
  margin: 0;
  flex-grow: 0 1 auto;
  display: block;
  background: none;
  color: ${palette("green", 1)};
  border: 0;
  outline: 0;
  font-family: ${theme("Font.RECOLETA")};
  font-size: 1.375rem;
  font-weight: 500;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    appearance: none;
  }
`;

export const CloseIcon = styled(CloseIconSvg)`
  flex: 0 0 auto;
  margin: 0.5rem 0 0.5rem 1rem;
  width: 1rem;
  height: 1rem;
  display: block;
  color: ${palette("black")};
  fill: currentColor;
  stroke: currentColor;
  stroke-width: 50;
  cursor: pointer;
`;
