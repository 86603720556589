import PropTypes from "prop-types";
import React, { useEffect } from "react";

export function Cart({ hidden }) {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://cdn2.circuly.io/v1/1.8/cart.js";
    script.defeer = true;
    script.type = "module";

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return hidden ? null : (
    // eslint-disable-next-line react/no-unknown-property
    <div id="cartIcon" data-embed="circuly-cart-button" persistent="true" />
  );
}

Cart.propTypes = {
  hidden: PropTypes.bool,
};

Cart.defaultProps = {
  hidden: false,
};

export default Cart;
