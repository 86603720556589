import React from "react";
import { Provider } from "react-redux";
import createStore from "../state/store";

// eslint-disable-next-line react/prop-types
function ReduxWrapper({ element }) {
  const store = createStore();
  return <Provider store={store}>{element}</Provider>;
}

export default ReduxWrapper;
