import styled from "styled-components";
import { palette, theme } from "styled-tools";

const TagStyled = styled.div`
  width: max-content;
  padding: 0.375rem 0.75rem;
  color: ${palette("yellow", 0)};
  font-weight: 700;
  font-size: 0.75rem;
  font-family: ${theme("Font.MUNKEN_SANS")};
  line-height: 1;
  text-transform: uppercase;
  background-color: ${palette("yellow", 1)};
  border-radius: 1rem;
`;

export default TagStyled;
