import PropTypes from "prop-types";
import React from "react";
import { MediaQuery } from "../../../config/theme";
import Col from "./styled";

function Column({ align, breakpoint, children, size }) {
  return (
    <Col $align={align} $breakpoint={breakpoint} $size={size}>
      {children}
    </Col>
  );
}

Column.displayName = "Column";

Column.propTypes = {
  align: PropTypes.string,
  breakpoint: PropTypes.oneOf(Object.values(MediaQuery)),
  children: PropTypes.node,
  size: PropTypes.number,
};

Column.defaultProps = {
  align: null,
  breakpoint: MediaQuery.SM_UP,
  children: null,
  size: 6,
};

export default Column;
