import React, { useEffect, useState } from "react";
import { Font } from "../../../../config/theme";
import { ParagraphStyled } from "./styled";

const formLabel = "Jetzt zu unserem Newsletter anmelden";

function KlaviyoForm() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=SFciLa";
    script.type = "text/javascript";
    script.async = true;
    script["data-cookieconsent"] = "marketing";

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div className="klaviyo-form-VpJC2W" />;
}

function EmailForm() {
  const [isHidden, setIsHidden] = useState(true);

  const handleConsentExecuted = () => {
    if (window.Cookiebot.consent.marketing) {
      setIsHidden(false);
    }
  };

  useEffect(() => {
    window.addEventListener("CookiebotOnTagsExecuted", handleConsentExecuted);

    return () => {
      window.removeEventListener(
        "CookiebotOnTagsExecuted",
        handleConsentExecuted,
      );
    };
  }, [isHidden]);

  return isHidden ? null : (
    <div>
      <ParagraphStyled font={Font.RECOLETA}>{formLabel}</ParagraphStyled>
      <KlaviyoForm />
    </div>
  );
}

export default EmailForm;
