import { Link } from "gatsby";
import React from "react";
import styled, { css } from "styled-components";
import { theme } from "styled-tools";

export const NavigationContainer = styled.div`
  display: flex;
`;

export const NavigationColumn = styled.div`
  width: 50%;
`;

const linkStyle = css`
  margin-bottom: 0.5rem;
  color: inherit;
  font-weight: normal;
  font-size: 0.875rem;
  font-family: ${theme("Font.MUNKEN_SANS")};
  line-height: 1.7;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const NavigationLinkExternal = styled.a`
  ${linkStyle}
`;

export const NavigationLink = styled((props) => <Link {...props} />)`
  ${linkStyle}
`;
