import { Link } from "gatsby";
import styled from "styled-components";
import { palette } from "styled-tools";
import Heading from "../../Heading";
import Paragraph from "../../Paragraph";

export const HeadingStyled = styled(Heading)`
  margin-bottom: 0.5rem;
  font-size: 1.375rem;
  font-weight: 500;
`;

export const Container = styled(Link)`
  display: flex;
  gap: 2rem;
  text-decoration: none;

  &:hover ${HeadingStyled} {
    text-decoration: underline;
  }
`;

export const Image = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: 5.625rem;
  height: 5.625rem;
  object-fit: cover;
  border-radius: 0.75rem;
  overflow: hidden;
`;

export const Content = styled.div`
  flex: 0 1 auto;
`;

export const ParagraphStyled = styled(Paragraph)`
  color: ${palette("gray", 0)};
  line-height: 1.333;
`;
