import { navigate } from "gatsby";
import React, { forwardRef, useContext } from "react";
import { SearchContext } from "./Context";
import ResultList from "./ResultList";
import { CloseIcon, InputContainer, InputStyled, SearchIcon } from "./styled";

function Search(_, ref) {
  const context = useContext(SearchContext);

  return (
    <>
      <InputContainer>
        <SearchIcon />
        <InputStyled
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          autoComplete="off"
          name="search"
          placeholder="Suchen ..."
          ref={ref}
          type="search"
          value={context.query}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => context.setQuery(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && context.query !== "") {
              context.closeSearch();
              if (context.results.length === 1) {
                navigate(context.results[0].path);
              } else {
                navigate(context.resultsPagePath);
              }
            }
          }}
        />
        <CloseIcon
          onClick={() => {
            context.closeSearch();
            context.setQuery("");
          }}
        />
      </InputContainer>
      <ResultList />
    </>
  );
}

export default forwardRef(Search);
