import PropTypes from "prop-types";
import React from "react";
import { Breakpoint } from "../../../config/theme";
import WrapperStyled from "./styled";

function Wrapper({ maxWidth, children, ...props }) {
  return (
    <WrapperStyled $maxWidth={maxWidth} {...props}>
      {children}
    </WrapperStyled>
  );
}

Wrapper.propTypes = {
  maxWidth: PropTypes.oneOf(Object.values(Breakpoint)),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Wrapper.defaultProps = {
  maxWidth: Breakpoint.LG,
};

export default Wrapper;
