export const Breakpoint = Object.freeze({
  XS: "22.5rem", // 360px
  SM: "43.75rem", // 700px
  MD: "60rem", // 960px
  LG: "75rem", // 1200px
  XL: "87.5rem", // 1400px
  TV: "131.25rem", // 2100px
});

export const Color = Object.freeze({
  BLACK: ["#000000"],
  GRAY: ["#1a2f32", "#505b56", "#d0d1d1", "#e5e6e6"],
  GREEN: ["#053333", "#376268", "#BCD0C7", "#DAE7DE"],
  PURPLE: ["#2D1D38", "#B19FAF", "#E6CFE3", "#EBE3EA"],
  RED: ["#682626", "#F97374", "#FDD5D3", "#FFEBEA"],
  YELLOW: ["#715B2F", "#FED56C", "#FDE4AC", "#FCF2E8"],
  WHITE: ["#ffffff"],
});

export const Font = Object.freeze({
  MUNKEN_SANS: `"Munken Sans", -system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif`,
  RECOLETA: `"Recoleta", serif`,
});

export const FontSize = Object.freeze({
  BASE: "1rem",
  LG: "1.5rem",
  SM: "0.875rem",
});

export const MediaQuery = Object.freeze(
  Object.fromEntries(
    Object.entries(Breakpoint).flatMap(([key, value]) => [
      [`${key}_DOWN`, `@media (max-width: ${value})`],
      [`${key}_UP`, `@media (min-width: ${value})`],
    ]),
  ),
);
