import PropTypes from "prop-types";
import React from "react";
import ColumnsWrapper from "./styled";

function Columns({ flipDesktop, justify, children }) {
  return (
    <ColumnsWrapper $flipDesktop={flipDesktop} $justify={justify}>
      {children}
    </ColumnsWrapper>
  );
}

Columns.displayName = "Columns";

Columns.propTypes = {
  children: PropTypes.node.isRequired,
  flipDesktop: PropTypes.bool,
  justify: PropTypes.string,
};

Columns.defaultProps = {
  flipDesktop: false,
  justify: "space-between",
};
export default Columns;
