import { Link, graphql, useStaticQuery } from "gatsby";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";
import EmailForm from "./EmailForm";
import Navigation from "./Navigation";
import SocialLinks from "./SocialLinks";
import { GridContainer, Logo, VersionContainer } from "./styled";

function Footer({ links }) {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          version
        }
        buildTime(formatString: "")
      }
    }
  `);

  const buildTime = DateTime.fromISO(data.site.buildTime, {
    setZone: true,
  }).setZone("Europe/Berlin");

  return (
    <GridContainer>
      <Link
        to="/"
        aria-label="Zur Startseite"
        style={{ textDecoration: "none" }}
      >
        <Logo />
        <VersionContainer>
          v{data.site.siteMetadata.version}
          <br />
          {buildTime.toFormat("dd.MM.y @ HH:mm")}
        </VersionContainer>
      </Link>
      <Navigation links={links} />
      <div>
        <EmailForm />
        <SocialLinks />
      </div>
    </GridContainer>
  );
}

Footer.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      target: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Footer;
