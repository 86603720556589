import { configureStore } from "@reduxjs/toolkit";
import productDetailsSlice from "./reducers/productDetailsSlice";

const store = () =>
  configureStore({
    reducer: {
      productDetail: productDetailsSlice,
    },
  });

export default store;
