import PropTypes from "prop-types";
import React from "react";
import TagStyled from "./styled";

function Tag({ children }) {
  return <TagStyled>{children}</TagStyled>;
}

Tag.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Tag;
