import PropTypes from "prop-types";
import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { palette } from "styled-tools";
import { ProductType } from "../../config/mapping/shopify";
import {
  Breakpoint,
  Color,
  Font,
  FontSize,
  MediaQuery,
} from "../../config/theme";
import Cart from "../parts/Cart";
import Container from "../parts/Container";
import Footer from "../parts/Footer";
import Navbar from "../parts/HeaderNavbar";
import NewsletterOverlay from "../parts/NewsletterOverlay";
import ReviewsWidget from "../parts/ReviewsWidget";
import { SearchProvider } from "../parts/Search/Context";
import Wrapper from "../parts/Wrapper";
import GlobalStyle from "./GlobalStyle";

const PageContainer = styled.div`
  background-color: ${palette("yellow", 3)};
`;

const FullScreenContainer = styled(PageContainer)`
  min-height: 100vh;
`;

const headerLinks = [
  { name: "Produkte", target: "/produkte" },
  { name: "Zubehör", target: "/zubehoer" },
];

const footerLinks = [
  { target: "/produkte", name: "Produkte" },
  { target: "/zubehoer", name: "Zubehör" },
  { target: "/circular-economy", name: "Circular Economy" },
  { target: "/refurbish-prozess", name: "Refurbish-Prozess" },
  { target: "/faq", name: "FAQ" },
  { target: "/ueber-uns", name: "Über uns" },
  { target: "https://strollme.jobs.personio.de/", name: "Jobs" },
  {
    target:
      "https://strollme.notion.site/Press-Room-94751177aacd44a99e33e72d5084655f",
    name: "Presse",
  },
  { target: "/impressum", name: "Impressum" },
  { target: "/datenschutz", name: "Datenschutz" },
  { target: "/agb", name: "AGB" },
  { target: "https://portal.strollme.de", name: "Abo kündigen" },
];

function Root({ children, pageContext }) {
  return (
    <ThemeProvider
      theme={{
        Color,
        Font,
        FontSize,
        Breakpoint,
        MediaQuery,

        // required for styled-tools' `palette` utility
        palette: Object.fromEntries(
          Object.entries(Color).map(([key, value]) => [
            key.toLowerCase(),
            value,
          ]),
        ),
      }}
    >
      <SearchProvider>
        <GlobalStyle />
        {pageContext?.templateName === ProductType.CONFIGURATOR ? (
          <>
            {children}
            <Cart hidden />
          </>
        ) : (
          <PageContainer>
            <FullScreenContainer>
              <Navbar links={headerLinks} />
              <NewsletterOverlay />
              {children}
            </FullScreenContainer>
            <Container>
              <Wrapper>
                <ReviewsWidget />
                <Footer links={footerLinks} />
              </Wrapper>
            </Container>
          </PageContainer>
        )}
      </SearchProvider>
    </ThemeProvider>
  );
}

Root.propTypes = {
  children: PropTypes.node.isRequired,
  pageContext: PropTypes.shape({
    templateName: PropTypes.oneOf(Object.values(ProductType)),
    isAccessory: PropTypes.bool,
  }),
};

Root.defaultProps = {
  pageContext: null,
};

export default Root;
