import styled from "styled-components";
import { prop } from "styled-tools";

const ParagraphStyled = styled.p`
  font-weight: ${prop("$fontWeight")};
  font-size: ${prop("$fontSize")};
  font-family: ${prop("$font")};
`;

export default ParagraphStyled;
