/* eslint-disable no-underscore-dangle */
import de from "date-fns/locale/de";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./src/static/fonts.css";
import wrapWithProvider from "./src/utils/wrap-with-provider";

registerLocale("de", de);
setDefaultLocale("de");

export const wrapRootElement = wrapWithProvider;

export const onClientEntry = () => {
  if (document.referrer.match(/^https?:\/\/checkout\.strollme\.de/i)) {
    window.location.reload();
  }
};
